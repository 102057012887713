import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>LekiTech Holding B.V.</title>
        <meta property="og:title" content="LekiTech Holding B.V." />
      </Helmet>
      <header data-role="Header" className="home-header">
        <img
          src="/playground_assets/lt%20copy-200h.png"
          alt="image"
          className="home-image"
        />
        <span>LekiTech Holding B.V</span>
      </header>
      <div className="home-feature-card"></div>
      <footer className="home-footer">
        <span className="home-text">
          © {new Date().getFullYear()} LekiTech Holding B.V., All Rights Reserved.
        </span>
        <a
          href="mailto:info@lekitechholdingbv.com"
          className="home-link"
        >
          <span>info@lekitechholdingbv.com</span>
          <br></br>
        </a>
      </footer>
    </div>
  )
}

export default Home
